import { AuthenticationService } from '../login/providers/authentication.service';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { TRACKER_SERVICE, TrackingService } from '../tracking/tracker.interface';
import { FetchResult } from '@apollo/client';
import { DateTime } from 'luxon';
import { isStringDateTime } from '@origin8-web/o8-utils/format';

export const extractGraphqlErrorMessage = (err: { graphQLErrors: { message: string }[] }): string =>
  err.graphQLErrors[0].message;

const parseDateFieldsInObject = (obj: object): object => {
  const keys = Object.keys(obj) as Array<keyof typeof obj & string>;
  return keys.reduce((acc, key) => {
    if (!obj[key]) {
      return { ...acc, [key]: obj[key] };
    }
    if (Array.isArray(obj[key])) {
      return { ...acc, [key]: parseDateFieldsInArray(obj[key]) };
    }
    if (typeof obj[key] === 'object') {
      return { ...acc, [key]: parseDateFieldsInObject(obj[key]) };
    }
    if (isStringDateTime(obj[key])) {
      return { ...acc, [key]: DateTime.fromISO(obj[key] as string).toJSDate() };
    }
    return { ...acc, [key]: obj[key] };
  }, {});
};

const parseDateFieldsInArray = (array: any[]): any[] => {
  if (array.length === 0) {
    return array;
  }
  return array.map((x) => {
    if (isStringDateTime(x)) {
      return DateTime.fromISO(x).toJSDate();
    }
    if (Array.isArray(x)) {
      return parseDateFieldsInArray(x);
    }
    if (typeof x === 'object') {
      return parseDateFieldsInObject(x);
    }
    return x;
  });
};

export const parseGqlData = (fetchResult: FetchResult): FetchResult => {
  const data = fetchResult.data;
  if (!data) {
    return fetchResult;
  }
  const parsedData = parseDateFieldsInObject(data);
  return { ...fetchResult, data: parsedData };
};

type ApolloGraphQLError = {
  message: string;
  extensions?: { code: string; response?: { statusCode: number } };
};

export type ParsedGraphQLError = {
  message: string;
  code: string;
  status: number;
};

export const parseGraphQlErrors = (e: { graphQLErrors: ApolloGraphQLError[] }) => {
  return throwError(() => {
    if (Array.isArray(e.graphQLErrors)) {
      return e.graphQLErrors.map((err) => {
        return {
          message: err.message,
          code: err.extensions?.code,
          status: err.extensions?.response?.statusCode || 500,
        };
      });
    }
    return {
      message: e,
    };
  });
};

@Injectable()
export class GraphQlUtils {
  constructor(
    @Inject(TRACKER_SERVICE) private trackerService: TrackingService,
    @Optional() private authenticationService: AuthenticationService,
  ) {}

  getAccessToken(): undefined | Promise<string | undefined> {
    // Needs to be a Promise and not an observable to be used in the graphql auth middleware.
    if (!this.authenticationService) {
      // Use case when the app does not use our the o8 login module => no authenticationService
      return undefined;
    }
    return firstValueFrom(this.authenticationService.getAccessToken$());
  }

  getRequestId(): string {
    return this.trackerService.getRequestId();
  }

  getSessionId(): string {
    return this.trackerService.getSessionId();
  }

  getImpersonationToken(): Promise<string> {
    return firstValueFrom(this.trackerService.getImpersonationToken());
  }
}
