import { SUPPORTED_STATE } from './global';
import { DateTime } from 'luxon';
import { REPRESENTATIVE_LEVEL } from './advocate';

export interface O8UserBaseInfo {
  email: string;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  roles: APP_ROLES[];
  permissions: APP_PERMS[];
}

export type O8UserExtraInfo = UserProfile;

export type O8User = O8UserBaseInfo & O8UserExtraInfo;

export enum APP_PERMS {
  CONFIGURATION_ARC_WRITE = 'write:arc-configuration',
  USER_PROFILE_READ_ALL = 'read:user-profile:all',
  USER_PROFILE_READ = 'read:user-profile',
  USER_PROFILE_WRITE = 'write:user-profile',
  USER_PROFILE_WRITE_ALL = 'write:user-profile:all',
  CALL_REVIEW_WRITE = 'write:call-review',
  CALL_REVIEW_READ = 'read:call-review',
  CALL_REVIEW_READ_ALL = 'read:call-review:all',
  ADVOCATE_CLICK_RECOMMENDED_TASK = 'write:advocate-click-recommended-task',
  ARC_FILTERED_VIEW_WRITE = 'write:arc-filtered-view',
  ADVOCATE_PAID_TIME_READ = 'read:advocate-paid-time',
  ADVOCATE_PAID_TIME_READ_ALL = 'read:advocate-paid-time:all',
  LEAD_INFO_READ = 'read:lead-info',
  LEAD_INFO_READ_ALL = 'read:lead-info:all',
  PIPELINE_READ_ALL = 'read:pipeline:all',
  WT_REQUEST_WRITE = 'write:wt-request',
  ADVOCATE_STATUS_READ = 'read:advocate-status',
  ADVOCATE_STATUS_READ_ALL = 'read:advocate-status:all',
  CALL_RECORDING_READ = 'read:call-recording',
  CALL_RECORDING_READ_ALL = 'read:call-recording:all',
  AB_EXPERIMENT_READ = 'read:ab-experiment',
  AB_EXPERIMENT_WRITE = 'write:ab-experiment',
  ARC_FILTERED_VIEW_READ = 'read:arc-filtered-view',
  ARC_FILTERED_VIEW_READ_ALL = 'read:arc-filtered-view:all',
  CALL_SCRIPT_READ_ALL = 'read:call-script:all',
  CALL_SCRIPT_WRITE_ALL = 'write:call-script:all',
  FINANCIAL_INFO_READ = 'read:financial',
  FINANCIAL_INFO_READ_ALL = 'read:financial:all',
  ARC_CONFIGURATION_READ = 'read:arc-configuration',
  ARC_CONFIGURATION_WRITE = 'write:arc-configuration',
}

export const READ_PERMISSIONS = Object.values(APP_PERMS).filter((perm) => perm.startsWith('read:'));
export const READ_ALL_PERMISSIONS = READ_PERMISSIONS.filter((perm) => perm.endsWith(':all'));
export const READ_SINGLE_PERMISSIONS = READ_PERMISSIONS.filter((perm) => !perm.endsWith(':all'));
export const WRITE_PERMISSIONS = Object.values(APP_PERMS).filter((perm) => perm.startsWith('write:'));
/*
When updating this, also update the graphql schema, they are defined as an enum there
*/
export enum APP_ROLES {
  MENTOR = 'Mentor',
  ADMIN = 'Admin',
  ADVOCATE = 'Advocate',
  TRAINER = 'Trainer',
  DEVELOPER = 'Developer',
  BETA_TESTER = 'BetaTester',
  BOARD = 'Board Member',
}

export interface Organization {
  id: string;
  name: string;
  isActive: boolean;
  users: UserProfile[];
  version: string;
}

export interface ExternalUser {
  id: string;
  email: string;
  isActive: boolean;
  name: string;
  firstName: string;
  lastName: string;
  organization: string;
  version: string;
}

export interface UserSkill {
  skillName: string /* State or product (tied to carrier)*/;
  skillLevel: string;
  externalIdentifier: string;
  externalSkillId: string;
  version: string;
  isDeleted: boolean;
}

export interface UserTalkdeskInfo {
  externalIdentifier: string;
  userAttributes: {
    id: string;
    active: boolean;
    name: string /* State or product (tied to carrier) or WT*/;
    userProficiency: string;
  }[];
  ringGroups: string[];
}

export interface UserWTSkill {
  name: WT_SKILL;
}
export interface UserProfile {
  id: string;
  firstName: string;
  lastName: string;
  sfUserRoleId?: string;
  name: string;
  email: string;
  skills?: UserSkill[];
  licensedStates?: SUPPORTED_STATE[];
  wtLicensedStates?: SUPPORTED_STATE[];
  wtSkills?: UserWTSkill[];
  approvedProducts?: string[];
  talkdeskInfo?: UserTalkdeskInfo | null;
  talkdeskUserId?: string;
  version: string;
  timezone?: string;
  isActive: boolean;
  authorizations: {
    roles: APP_ROLES[];
    permissions: APP_PERMS[];
  };
  advocateLevel: REPRESENTATIVE_LEVEL | null;
  organization: string;
}

export interface UserRoleTimeSerie {
  advocateLevel: REPRESENTATIVE_LEVEL | null;
  userRoleId: string;
  userId: string;
  email: string;
  timestamp: Date;
  eventId: string;
}

export type RepresentativeProfile = Pick<
  UserProfile,
  | 'advocateLevel'
  | 'email'
  | 'talkdeskUserId'
  | 'name'
  | 'licensedStates'
  | 'wtLicensedStates'
  | 'approvedProducts'
  | 'timezone'
  | 'wtSkills'
>;

export interface UserAppointment {
  externalIdentifier: string;
  ownerId: string;
  ownerName: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  subject: string;
  leadExternalIdentifier: string;
}

/* Remove on OWEB-1066 */
export const LEGACY_WT_SKILL = 'CNO Warm Transfer';

export const WT_SKILL_PREFIX = 'Warm Transfer';
export enum WT_SKILL {
  CNO = `${WT_SKILL_PREFIX}[Colonial Penn]`,
  LEGACY_CNO = LEGACY_WT_SKILL,
  POLICY_SCOUT = `${WT_SKILL_PREFIX}[Policy Scout]`,
  INTERNAL = `${WT_SKILL_PREFIX}[Internal]`,
  NEW_PARTNER = `${WT_SKILL_PREFIX}[New Partner]`,
}
